import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Modal, BSModalContext } from 'ngx-modialog/plugins/bootstrap';
import { Overlay, overlayConfigFactory } from 'ngx-modialog';

import { AppEvent, AppService } from '@shared/app.service';
import { SeoService } from '@shared/seo.service';
import { EmailSignUpDialog } from '@shared/emailSignUp.dialog';

declare let jQuery: any;

@Component({
    selector: 'app-root',
    template: `
        <header #navBar class="navbar">
            <a routerLink="/" class="logo svg-link">
                <object type="image/svg+xml" data="/assets/logo-long.svg" class="mt-1 mt-md-0" style="max-width:100%;">
                    <img src="/assets/logo-long.png" alt="No SVG support" />
                </object>
            </a>

            <nav class="nav navbar-nav d-none d-lg-flex">
                <ul>
                    <li><a routerLink="/pricing">Pricing</a></li>
                    <li dropdown class="nav-item dropdown">
                        <a dropdownToggle class="nav-link dropdown-toggle" aria-controls="dropdown-features" style="cursor:pointer;">Features</a>
                        <div id="dropdown-features" *dropdownMenu class="dropdown-menu" role="menu">
                            <app-features-nav-menu></app-features-nav-menu>
                        </div>
                    </li>
                    <li><a routerLink="/faq">FAQ</a></li>
                    <li><a [href]="appEndpoint('sign-up')">Sign Up</a></li>
                    <li><a routerLink="/demo">Demo</a></li>
                    <li><a [href]="appEndpoint('login')" class="btn btn-brand-b rounded-10 text-white">
                        <i class="ki ki-lock ki-x133" style="vertical-align:inherit;"></i> Login</a>
                    </li>
                </ul>
            </nav>
            <a class="d-flex d-lg-none" (click)="showMenu = !showMenu"><i class="ki ki-menu ki-x200"></i></a>
        </header>
        
        <div *ngIf="showMenu" id="mobile-menu" [ngStyle]="{'top.px': navBarHeight}">
            <ul>
                <li><a routerLink="/pricing">Pricing</a></li>
                <li>
                    <a routerLink="/features">Features</a>
                    <app-features-nav-menu></app-features-nav-menu>
                </li>
                <li><a routerLink="/faq">FAQ</a></li>
                <li><a [href]="appEndpoint('sign-up')">Sign Up</a></li>
                <li><a routerLink="/demo">Demo</a></li>
                <li><a [href]="appEndpoint('login')">
                    <i class="ki ki-lock ki-x133" style="vertical-align:bottom;"></i>Login</a>
                </li>
            </ul>
        </div>

        <div [ngStyle]="{'margin-top.px': navBarHeight}">
            <router-outlet></router-outlet>
        </div>
        
        <div #vSpacer class="container-fluid" [style.height.px]="spacerHeight"></div>
        <!--<section *ngIf="showFeatureNav" id="feature-nav" class="mt-4">
            <div class="container">
                <nav class="nav navbar-nav">
                    <app-features-nav-menu [active]="activeFeature"></app-features-nav-menu>
                </nav>
            </div>
        </section>-->
        <section id="call-to-action" class="mt-4 d-none d-lg-block">
            <div class="container">
                <div class="text-center">
                    <h4>30 Day FREE Trial - No Credit Card Required</h4>
                    <a [href]="appEndpoint('sign-up')" class="btn btn-brand-b btn-lg mr-3">Sign Up</a>
                    <a [href]="appEndpoint('create-demo')" class="btn btn-brand-c btn-lg">Demo</a>
                </div>
            </div>
        </section>
        <section id="sales-newsletter">
            <div class="container">
                <div class="row">
                    <div class="col-4">
                        <h3>Newsletter</h3>
                        <p>
                            Get the updates about the latest feature additions, channel integrations and more.
                        </p>
                        <button type="button" class="btn btn-white" (click)="mailingListSignup()">Newsletter</button>
                    </div>
                    <div class="col-4">
                        <h3>Features</h3>
                        <app-features-nav-menu [active]="activeFeature" ul-class="list-unstyled"></app-features-nav-menu>
                    </div>
                    <div class="col-4">
                        <h3>Links</h3>
                        <ul class="list-unstyled">
                            <li><a routerLink="/pricing">Pricing</a></li>
                            <li><a [href]="appEndpoint('sign-up')">30 Day FREE Trial</a></li>
                            <li><a routerLink="/demo">Demo Account</a></li>
                            <li><a routerLink="/faq">Frequently Asked Questions</a></li>
                            <li><a routerLink="/security">Security</a></li>
                            <li><h5>Company</h5></li>
                            <li>We are entirely US based including developers and support staff.</li>
                            <!--<li><a routerLink="/about-us">About Us</a></li>-->
                            <!--<li>{{appConfig.address}}</li>-->
                            <!--<li><a [routerLink]="" (click)="contactForm()">Contact via Email</a></li>-->
                            <li>Phone: {{appConfig.phone}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <nav class="nav navbar-nav">
                <ul>
                    <li>&copy; 2017-{{year}} {{appConfig.copyright}}, All Rights Reserved</li>
                    <li><a routerLink="/legal/terms">Terms &amp; Conditions</a></li>
                    <li><a routerLink="/legal/privacy">Privacy Policy</a></li>
                </ul>
            </nav>
        </footer>
        
        <div id="lower-third" class="d-block d-lg-none">
            <div class="btn-group justify-content-center">
                <a [href]="appEndpoint('login')" class="btn btn-brand-d px-1">Login</a>
                <a [href]="appEndpoint('sign-up')" class="btn btn-brand-b px-1">Sign Up</a>
                <a [href]="appEndpoint('create-demo')" class="btn btn-brand-c px-1">Demo</a>
            </div>
        </div>
    `
})
export class AppComponent implements OnInit {
    @ViewChild('navBar', { static: false }) navBarEl;
    @ViewChild('vSpacer', { static: false }) private vSpacer;

    appConfig = AppService.config;

    spacerHeight = 0;
    spacerTimer = null;
    spacerCount = 0;

    year: number = 2018;
    navBarHeight: number = 110;
    showMenu: boolean = false;
    showFeatureNav: boolean = false;
    activeFeature: string = '';

    constructor(
        private router: Router,
        private seo: SeoService,
        private appService: AppService,
        private modal: Modal,
    ) {
        this.year = new Date().getFullYear();

        // environment sets which service is used (fake or real)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showMenu = false;
                this.setSpacerHeight(true);
                (<any>window).scrollTo(0, 0);

                const navSplit = event.urlAfterRedirects.split(/(;|#)/)[0].split('/').filter((p) => {
                    return p != '';
                });

                if (navSplit[0] == 'features') {
                    this.showFeatureNav = true;
                    this.activeFeature = navSplit[1];
                } else {
                    this.showFeatureNav = false;
                    this.activeFeature = '';
                }

                this.seo.update(navSplit);
            }
        });
    }

    ngOnInit(): void {
        jQuery((<any>window)).resize(() => {
            this.navBarHeight = jQuery(this.navBarEl.nativeElement).outerHeight();
            AppService.config.settings.navBarHeight = this.navBarHeight;
        });
        setTimeout(() => {
            this.navBarHeight = jQuery(this.navBarEl.nativeElement).outerHeight();
            AppService.config.settings.navBarHeight = this.navBarHeight;
        }, 500);
    }

    setSpacerHeight(reset: boolean) {
        if (this.spacerTimer !== null)
            clearInterval(this.spacerTimer);

        if (reset == true) {
            this.spacerHeight = 0;
            this.spacerCount = 0;
        }

        this.spacerTimer = setInterval(() => {
            const wh = (<any>window).innerHeight;
            // const bh = (<any>document).body.offsetHeight;
            const bh = Math.floor(jQuery('body').height());
            const nh = this.navBarEl.nativeElement.offsetHeight;
            // const nh = Math.floor(jQuery(this.navBarEl.nativeElement).height());
            const sh = wh - (bh + nh);

            if (sh > 0) {
                this.spacerHeight = sh;
            } else {
                this.spacerCount++;
            }

            if (this.spacerCount > 10) {
                clearInterval(this.spacerTimer);
                console.debug('Done checking for page spacing adjustments');
            }
        }, 250);
    }

    appEndpoint = AppService.config.appEndpoint;

    mailingListSignup() {
        this.modal.open(
            EmailSignUpDialog, overlayConfigFactory({}, BSModalContext)
        ).result.then(() => {
            // console.log('complete');
        }, () => {
            // have to have a cancel method
        });
    }

    contactForm() {

    }
}
