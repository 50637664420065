import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-subscription',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_billing.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Flexible Billing</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Lowest Price Each Month, Automatically!</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!--<div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-dashboard.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/dashboard.mp4" type="video/mp4">
                            </video>
                        </div>-->
                        <ul class="branded mb-4">
                            <li>30 days free with all features for trial</li>
                            <li>Ability to review and select features on a monthly basis</li>
                            <li>Add features at any time (with a one full month billing cycle requirement)</li>
                        </ul>
                        <p>
                            Your monthly bill will automatically adjust to the lowest price based on your usage.
                            Allows for discounted months throughout the year based on sales seasons.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class SubscriptionComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
