import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-returns',
    template: `        
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_returns.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Return Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Handle Returns Your Way</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-returns.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/returns.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Restock returned items</li>
                            <li>Custom return reasons for tracking</li>
                            <li>Indicating refunded amounts for accurate sales and return data reporting</li>
                        </ul>
                        <p>
                            Keep internal notes regarding returns including return reasons. Return quantities to your
                            inventory for returns or damage them out. Provide the refunded amount to automatically
                            update your sales reporting data.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class ReturnsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
