import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-features-nav-menu',
    template: `
        <ul [ngClass]="ulClass">
            <li [ngClass]="{'active': active == 'dashboard'}"><a routerLink="/features/dashboard">Dashboard Overview</a></li>
            <li [ngClass]="{'active': active == 'orders'}"><a routerLink="/features/orders">Order Management</a></li>
            <li [ngClass]="{'active': active == 'returns'}"><a routerLink="/features/returns">Return Processing</a></li>
            <li [ngClass]="{'active': active == 'products'}"><a routerLink="/features/products">Product Management</a></li>
            <li [ngClass]="{'active': active == 'bundles'}"><a routerLink="/features/bundles">Bundle/Packages</a></li>
            <!--<li><a routerLink="/features/groups">Product Grouping</a></li>-->
            <li [ngClass]="{'active': active == 'pricing'}"><a routerLink="/features/pricing">Product Pricing</a></li>
            <li [ngClass]="{'active': active == 'vendors'}"><a routerLink="/features/vendors">Vendor Management</a></li>
            <li [ngClass]="{'active': active == 'import-export'}"><a routerLink="/features/import-export">Product Import/Export</a></li>
            <li [ngClass]="{'active': active == 'tasks'}"><a routerLink="/features/tasks">To Do &amp; Tasks</a></li>
            <li [ngClass]="{'active': active == 'reports'}"><a routerLink="/features/reports">Report Generation</a></li>
            <li [ngClass]="{'active': active == 'integrations'}"><a routerLink="/features/integrations">Channel Integrations</a></li>
            <!--<li><a routerLink="/features/packing-slips">Custom Packing Slips</a></li>-->
            <!--<li><a routerLink="/features/subscription">Flexible Billing</a></li>-->
        </ul>
    `
})
export class NavMenuComponent {
    @Input('ul-class') ulClass: string = '';
    @Input('active') active: string = '';
}
