import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-reports',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_reports.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Report Generation</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Reporting Made Simple</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-reports.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/reports.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Reports based on sales</li>
                            <li>Metrics per product</li>
                            <li>Drill-Down report from custom hierarchical product tags</li>
                            <li>Sales velocity</li>
                            <!--<li>Sales Tax Report**</li>-->
                            <!--<li>Calculation Report**</li>-->
                            <li>Instant report generation</li>
                        </ul>
                        <p>
                            Create your own custom tagging for reports based on date range or see your sales reports on
                            a per product basis.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class ReportsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
