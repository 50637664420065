/* Node */
import { Injectable } from '@angular/core';
import { Observable , Subject } from 'rxjs';

export interface AppEvent {
    target: string,
    type: string,
    data?: any
}

@Injectable()
export class AppService {
    static config = {
        name: 'Epitome Inventory Manager',
        shortName: 'Epitome',
        longName: 'Epitome Inventory and Order Management',
        copyright: 'Epitome Inventory and Order Management, LLC',
        address: '4181 14th Street North, Saint Petersburg, FL 33703',
        phone: '727-344-9640',
        backendVersion: null,
        mwsDeveloper: {name: 'Porcelynne', id: '453562677871'},
        mailChimpEndpoint: 'https://epitomeinventory.us19.list-manage.com/subscribe/post-json?u=121ce0ea55d56121c466270f2&id=f63c45798d',

        domain: function() {
            return (['localhost', 'serve-4200.internal'].indexOf(location.hostname) > -1)
                ? 'kizarian.internal'
                : location.hostname.split('.').slice(-2).join('.');
        },

        /**
         * Function to make API endpoint URLs
         */
        apiEndpoint: function(path: string = '') {
            let location = (<any>window).location;

            if (path != '' && (/^\//).test(path) == false) {
                path = '/' + path;
            }

            const domain = (['localhost', 'serve-4200.internal'].indexOf(location.hostname) > -1)
                ? 'https://api.kizarian.internal'
                : 'https://api.' + location.hostname.split('.').slice(-2).join('.');

            return (path != '') ? `${domain}${path}` : domain;
        },
        appEndpoint: function(path: string = '') {
            let location = (<any>window).location;

            if (path != '' && (/^\//).test(path) == false) {
                path = '/' + path;
            }

            const domain = (['localhost', 'serve-4200.internal'].indexOf(location.hostname) > -1)
                ? 'https://app.kizarian.internal'
                : 'https://app.' + location.hostname.split('.').slice(-2).join('.');

            return (path != '') ? `${domain}${path}` : domain;
        },
        /**
         * In-app constants
         */
        settings: {
            screens: {
                'xs-max': 543,
                'sm-min': 544,
                'sm-max': 767,
                'md-min': 768,
                'md-max': 991,
                'lg-min': 992,
                'lg-max': 1199,
                'xl-min': 1200
            },
            navBarHeight: 110
        },

        allSources: ['Amazon', 'BigCommerce', 'eBay', 'Etsy', 'Sears', 'Walmart', 'ZenCart'],

        dateFormat: {
            standard: 'M/D/YYYY, h:mm A',
            shortDay: 'MMM D, YYYY',
            full: 'MMMM D, YYYY, h:mm A',
            fullDay: 'MMMM D, YYYY'
        },

        googleRecaptcha: {
            siteKey: '6LeYhHUUAAAAALZyIIBrtBmx5z9ep6JHAsW_boAF'
        },

        featureCosts: {
            additionalChannels: 10,
            calculations: 0,
            components: 0,
            packingSlips: 5
        }
    };

    private appEventSubject: Subject<AppEvent> = new Subject<AppEvent>();

    // constructor() {
    //     window['__theme'] = 'bs4';
    // }

    appEventAsObservable(): Observable<AppEvent> {
        return this.appEventSubject.asObservable();
    }

    fireAppEvent(event: AppEvent) {
        if (event.hasOwnProperty('data') && event.data === null)
            event.data = {};

        this.appEventSubject.next(event);
    }

    static isProduction(): boolean {
        let location = (<any>window).location;

        return !(location.hostname == 'localhost' || (/\.(local|internal|dev)$/).test(location.hostname));
    }

    static chunk(array, size) {
        const chunked_arr = [];
        let index = 0;
        while (index < array.length) {
            chunked_arr.push(array.slice(index, size + index));
            index += size;
        }
        return chunked_arr;
    }
}
