import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-dashboard',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_dashboard.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Dashboard Summary</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Quickly See Your Order Summary</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-dashboard.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/dashboard.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Quick view of orders ready to ship and pending</li>
                            <li>Order totals of last 24 hours and last 30 days</li>
                            <li>Quick view of products active, inactive and at low quantity thresholds</li>
                            <li>SKU count for each sales channel</li>
                            <li>30 day snapshot for order count and value, and SKU counts</li>
                        </ul>
                        <p>
                            The dashboard provides a quick overview of your status for the day. Easily see how many
                            orders need to be shipped, how many products are at or below their custom re-order threshold
                            and a summary view of your sales trends for the past thirty days.
                        </p>
                        <p>
                            The products widget summarizes your total product catalog and breaks down the quantity of
                            items listed on all the sales channels you have connected.
                        </p>
                        <p>
                            The thirty day snapshot provides a look at your sales through six different metrics in three
                            different graphs. These metrics  help to visualize your sales trends to better understand
                            your customers.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class DashboardComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
