import { Component, OnInit } from '@angular/core';
import { Modal, BSModalContext } from 'ngx-modialog/plugins/bootstrap';
import { Overlay, overlayConfigFactory } from 'ngx-modialog';
import { PriceCalculatorDialog } from '@shared/priceCalculator.dialog';
import { AppService } from "@shared/app.service";

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html'
})
export class PricingComponent implements OnInit {
    appConfig = AppService.config;

    constructor(
        private appService: AppService,
        private modal: Modal
    ) {}

    appEndpoint = AppService.config.appEndpoint;

    ngOnInit() {}

    contactUs() {
        this.appService.fireAppEvent({target: 'contact', type: 'open'});
    }

    openEstimator() {
        this.modal.open(
            PriceCalculatorDialog, overlayConfigFactory({}, BSModalContext)
        ).result.then(() => {
            // console.log('complete');
        }, () => {
            // have to have a cancel method
        });
    }
}
