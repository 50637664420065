import { Component, } from '@angular/core';
import { AppService } from '@shared/app.service';

@Component({
    selector: 'app-security',
    template: `
        <div id="security-statement" class="container">
            <div class="row">
                <div class="col col-12 col-md-8">
                    <h1>Security</h1>
                    <p>
                        We employ modern best practices in all aspects of our business. This is particularly true when
                        it comes to security concerns. We regularly review our security and look for ways to improve.
                    </p>
                    <ul>
                        <li>
                            We are an entirely U.S. based company and your data always stay in the U.S. All information
                            about you, your business or your customers is NEVER sold, shared or distributed in any way
                            without your expressed written permission.
                        </li>
                        <li>
                            Utilize the AWS cloud service with monitoring and automated backups performed regularly.
                            Server access is strictly controlled and only our in-house team have access to it. Third
                            parties such as contracted developers never have access.
                        </li>
                        <li>
                            All application endpoints are protected with 2048 bit SSL certificates to ensure your privacy.
                            This means that your data is encrypted as it is in transit between you and our servers.
                        </li>
                        <li>
                            {{appConfig.shortName}} managers are alerted immediately when security related events occur. 
                            We do not rely on our users to inform us that a problem exists.
                        </li>
                        <li>
                            Your sensitive information such as sales channel connection credentials are all stored with
                            AES 256 encryption at rest. This includes sales channel connection credentials as well as
                            your customer's shipping information.
                        </li>
                        <li>
                            We NEVER store you password in plain text or any decryptable format.
                        </li>
                        <li>
                            We NEVER handle your payment information in any way. We maintain full PCI compliance by using
                            Braintree Payments for payment processing. The payment forms found within the application
                            are windows to Braintree. 
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <img src="/assets/images/lock-1068349.jpg" class="w-100" />
                </div>
            </div>
        </div>
    `
})
export class SecurityComponent {
    appConfig = AppService.config;
}
