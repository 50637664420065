import { Component } from '@angular/core';
import { BSModalContext } from 'ngx-modialog/plugins/bootstrap';
import { DialogRef, ModalComponent } from 'ngx-modialog';
import { AppService } from "@shared/app.service";

@Component({
    selector: 'modal-content',
    template: `
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
            <h3 class="modal-title">Monthly Charge Estimator</h3>
        </div>
        <div class="modal-body">
            <monthly-price-calculator></monthly-price-calculator>
        </div>
        <div class="modal-footer">
            <a [href]="appEndpoint('sign-up')" class="btn btn-brand-a">Get 30 Day FREE Trial</a>
            <button class="btn btn-default" (click)="cancel()">Close</button>
        </div>
    `
})
export class PriceCalculatorDialog implements ModalComponent<BSModalContext> {
    private context: BSModalContext;

    constructor(
        public dialog: DialogRef<BSModalContext>
    ) {
        this.context = dialog.context;
    }

    appEndpoint = AppService.config.appEndpoint;

    cancel() {
        this.dialog.dismiss();
    }
}
