import { Component, OnInit } from '@angular/core';
import { AppService } from "@shared/app.service";

@Component({
    selector: 'app-features-pricing',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_pricing.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Pricing Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Calculate Your Product Prices</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-pricing-tools.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/pricing-tools.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Base price for reference and Expanded Product Pricing feature</li>
                            <li>Separate listing price for each sales channels</li>
                            <li>Track product costs for profit reporting</li>
                        </ul>
                        <p>
                            Basic costing tools are included to calculate your cost based on a price per piece, package
                            and miscellaneous cost field. You can individually set your price for each sales channel and
                            base price manually. Setting custom price calculations for both the base price and the sale
                            channel are part of the Enhanced Pricing Management Feature.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <h2 class="mb-4 hr">
                            Product Price Calculators
                            <span class="badge badge-brand-b float-right">{{appConfig.featureCosts.calculations | currency:'USD':'symbol':'1.0'}} Add-On</span>
                        </h2>
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-calculator.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/calculator.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Complex product costs</li>
                            <li>Base price calculations based on product costs</li>
                            <li>Listing price based on base price</li>
                            <li>Incorporate fees and free shipping into product prices</li>
                        </ul>
                        <p>
                            The Enhanced Pricing Management Feature is an add-on module that allows you to completely 
                            customize how your base prices and your prices for each sales channel is created. 
                            Incorporated anything from a simple markup to a complex calculation incorporating a shipping
                            cost, markup and a packaging cost.
                        </p>
                        <p>
                            Create and set global variables to make updating prices easy when postage prices increase or
                            the fees change on your marketplace.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class PricingComponent implements OnInit {

    appConfig = AppService.config;

    constructor() { }

    ngOnInit() {
    }

}
