import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-packings',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_invoice.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Invoice and Package Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Custom Packing Slips</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!--<div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-orders.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/orders.mp4" type="video/mp4">
                            </video>
                        </div>-->
                        <ul class="branded mb-4">
                            <li>Include branded logo/customized logo per sales channel</li>
                            <li>Custom business information</li>
                            <li>Add product related information</li>
                            <li>Custom customer footer message</li>
                            <li>Choose invoice* and/or packing slip options</li>
                            <!--<li>Create customized commercial invoice for international orders</li>-->
                            <li>Custom templates for flat fee of $50 each template</li>
                        </ul>
                        <p>
                            The order detail page automatically imports your orders from all connected sales channels.
                            Easy one click packing slip creation for pull and pack with the ability to provide custom
                            packing slip messaging.
                        </p>
                        <p>
                            Selecting an order provides details for all order specifics including shipping method
                            requested and the list of products purchased. This also allows you to change the shipping
                            date and send it to the Pending Orders page.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class PackingSlipsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
