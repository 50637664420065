import { Component, } from '@angular/core';
import { AppService } from '@shared/app.service';

@Component({
  selector: 'app-about-us',
  template: `
      <div class="container">
          <div class="row">
              <div class="col col-8">
                  <h1 class="script-font">Instant Access Demo Account</h1>
                  <p>
                      Creating a demo account takes just a few seconds. Generating the dummy data for the new demo account
                      takes a couple of minutes. Once you verify that you're a real person, you'll be directed into the
                      interface. In the background our server is working hard to generate products, fake orders and report
                      data.
                  </p>
                  <a [href]="appEndpoint('create-demo')" class="btn btn-success">Explore {{appConfig.shortName}} Now</a>
              </div>
          </div>
      </div>
  `
})
export class DemoComponent {
  appConfig = AppService.config;
  appEndpoint = AppService.config.appEndpoint;
}
