import { Component, OnInit } from '@angular/core';
import { AppService } from "@shared/app.service";

@Component({
    selector: 'monthly-price-calculator',
    template: `
        <div role="form">
            <fieldset>
                <div class="form-group row">
                    <label for="orders" class="col-4 col-form-label text-right font-weight-bold">Orders per Month</label>
                    <div class="col-8">
                        <input type="number" id="orders" class="form-control" [(ngModel)]="params.orders" (change)="updateMonthly()" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="channels" class="col-4 col-form-label text-right font-weight-bold">Sales Channels</label>
                    <div class="col-8">
                        <input type="number" id="channels" class="form-control" [(ngModel)]="params.channels" (change)="updateMonthly()" />
                        <p>
                            Three sales channels are included with every plan. Additionally a POS implementation is provided FREE.
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="calculations" class="col-4 col-form-label text-right font-weight-bold">Calculation</label>
                    <div class="col-8">
                        <div class="abc-checkbox">
                            <input type="checkbox" id="calculations" [(ngModel)]="params.calculations" (change)="updateMonthly()" />
                            <label for="calculations">
                                <s>Enable</s>
                                <strong class="ml-2 text-success">No Additional Cost</strong>
                            </label>
                        </div>
                        <p class="help-block">
                            This features offers expanded costing and pricing capabilities through user created calculations.
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="components" class="col-4 col-form-label text-right font-weight-bold">Bundles &amp; Packages</label>
                    <div class="col-8">
                        <div class="abc-checkbox">
                            <input type="checkbox" id="components" [(ngModel)]="params.components" (change)="updateMonthly()" />
                            <label for="components">
                                <s>Enable</s>
                                <strong class="ml-2 text-success">No Additional Cost</strong>
                            </label>
                        </div>
                        <p class="help-block">
                            This feature allows packaged product from other products in your catalog.
                        </p>
                    </div>
                </div>
            </fieldset>
        </div>
        <div>
            <h4 *ngIf="customPrice == false">Estimated Monthly Cost: {{monthly | currency}}</h4>
            <h4 *ngIf="customPrice">Monthly Cost Starts At: {{monthly | currency}}, <a [routerLink]="" (click)="contactUs()">contact us for a quote</a></h4>
        </div>
    `
})
export class PriceCalculatorComponent implements OnInit {
    params = {
        orders: 250,
        channels: 2,
        calculations: true,
        components: true
    };

    monthly: number = 0;
    customPrice: boolean = false;

    constructor(private appService: AppService) {
    }

    ngOnInit() {
        this.updateMonthly();
    }

    contactUs() {
        this.appService.fireAppEvent({target: 'contact', type: 'open'});
    }

    /*
    'orders'   => [
        'b500'  => ['below' => 500, 'monthly' => 9.95],
        'b1k'   => ['below' => 1000, 'monthly' => 39.95],
        'b2500' => ['below' => 2500, 'monthly' => 69.95],
        'b5k'   => ['below' => 5000, 'monthly' => 139.95],
        'b10k'  => ['below' => 10000, 'monthly' => 299.95],
    ],
     */

    updateMonthly() {
        this.customPrice = false;
        this.monthly = 0;
        switch (true) {
            case (this.params.orders < 500) :
                this.monthly += 9.95;
                break;
            case (this.params.orders < 1000) :
                this.monthly += 39.95;
                break;
            case (this.params.orders < 2500) :
                this.monthly += 69.95;
                break;
            case (this.params.orders < 5000) :
                this.monthly += 139.95;
                break;
            case (this.params.orders < 10000) :
                this.monthly += 299.95;
                break;
            default :
                this.monthly += 299.95;
                this.customPrice = true;
        }

        const channels = (this.params.channels - 3) * 10;
        if (channels > 0) {
            this.monthly += channels;
        }

        // if (this.params.calculations) {
        //     this.monthly += 20;
        // }

        // if (this.params.components) {
        //     this.monthly += 10;
        // }
    }
}
