import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ModalModule } from 'ngx-modialog';
import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';
import { AlertModule, BsDropdownModule } from 'ngx-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';

import { environment } from '@env/environment';
import { AppService } from '@shared/app.service';
import { SeoService } from '@shared/seo.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HomeFeaturesComponent } from '@app/home/features.component';
import { NavMenuComponent as FeaturesNavMenuComponent } from '@app/features/navMenu.component';
import { MainComponent as FeaturesComponent } from '@app/features/main.component';
import { DashboardComponent as DashboardFeatureComponent } from '@app/features/dashboard.component';
import { OrdersComponent as OrdersFeatureComponent } from '@app/features/orders.component';
import { ReturnsComponent as ReturnsFeatureComponent } from '@app/features/returns.component';
import { ProductsComponent as ProductsFeatureComponent } from '@app/features/products.component';
import { BundlesComponent as BundlesFeatureComponent } from '@app/features/bundles.component';
import { PricingComponent as PricingFeatureComponent } from '@app/features/pricing.component';
import { VendorsComponent as VendorsFeatureComponent } from '@app/features/vendors.component';
import { ImportExportComponent as ImportExportFeatureComponent } from '@app/features/importExport.component';
import { TasksComponent as TasksFeatureComponent } from '@app/features/tasks.component';
import { ReportsComponent as ReportsFeatureComponent } from '@app/features/reports.component';
import { IntegrationsComponent as IntegrationsFeatureComponent } from '@app/features/integrations.component';
import { PackingSlipsComponent as PackingSlipsFeatureComponent } from '@app/features/packingSlips.component';
import { SubscriptionComponent as SubscriptionFeatureComponent } from '@app/features/subscription.component';
import { AboutUsComponent } from '@app/additional/aboutUs.component';
import { DemoComponent } from '@app/additional/demo.component';
import { FaqComponent } from '@app/additional/faq.component';
import { SecurityComponent } from '@app/additional/security.component';
import { PricingComponent } from '@app/pricing/pricing.component';
import { PriceCalculatorComponent } from '@shared/priceCalculator.component';
import { EmailSignUpComponent } from '@shared/emailSignUp.component';
import { EmailSignUpDialog } from '@shared/emailSignUp.dialog';
import { PriceCalculatorDialog } from '@shared/priceCalculator.dialog';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AlertModule.forRoot(),
        ModalModule.forRoot(),
        BootstrapModalModule,
        BsDropdownModule.forRoot(),
        RecaptchaModule.forRoot(),
        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        HomeFeaturesComponent,
        PricingComponent,
        PriceCalculatorComponent,
        FeaturesComponent,
        FeaturesNavMenuComponent,
        DashboardFeatureComponent,
        OrdersFeatureComponent,
        ReturnsFeatureComponent,
        ProductsFeatureComponent,
        BundlesFeatureComponent,
        PricingFeatureComponent,
        VendorsFeatureComponent,
        ImportExportFeatureComponent,
        TasksFeatureComponent,
        ReportsFeatureComponent,
        IntegrationsFeatureComponent,
        PackingSlipsFeatureComponent,
        SubscriptionFeatureComponent,
        AboutUsComponent,
        DemoComponent,
        FaqComponent,
        SecurityComponent,
        EmailSignUpComponent,
        EmailSignUpDialog,
        PriceCalculatorDialog
    ],
    entryComponents: [
        EmailSignUpDialog,
        PriceCalculatorDialog
    ],
    providers: [
        Title,
        SeoService,
        AppService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
