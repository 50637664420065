import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from '@shared/app.service';

interface MailChimpResponse {
    result: string;
    msg: string;
}

@Component({
    selector: 'email-list-signup',
    template: `
        <p *ngIf="showDescription">
            To get the latest updates and feature additions, please provide us with your contact information. Because we
            respect your time, we will only be sending emails about once a month.
        </p>
        <div role="form">
            <fieldset>
                <div class="form-group row">
                    <label for="email" class="col-4 col-form-label text-right">Email</label>
                    <div class="col-8">
                        <input type="email" id="email" class="form-control" [(ngModel)]="fields.EMAIL"
                               (keyup)="checkEmail()"
                               [ngClass]="validation.email == true ? 'is-valid' : 'is-invalid'">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="firstName" class="col-4 col-form-label text-right">First Name</label>
                    <div class="col-8">
                        <input type="text" id="firstName" class="form-control" [(ngModel)]="fields.FNAME">
                    </div>
                </div>
                <!--<div class="form-group row">
                    <label for="lastName" class="col-4 col-form-label text-right">Last Name</label>
                    <div class="col-8">
                        <input type="text" id="lastName" class="form-control" [(ngModel)]="fields.LNAME">
                    </div>
                </div>-->
                <div class="form-group">
                    <label for="marketplaces">What marketplaces do you sell on?</label>
                    <input type="text" id="marketplaces" class="form-control" placeholder="Amazon, Etsy, Walmart" [(ngModel)]="fields.MMERGE6">
                </div>
                <div class="form-group">
                    <label for="storefront">What storefronts do you use?</label>
                    <input type="text" id="storefront" class="form-control" placeholder="Open Cart, WooCommerce, Zen Cart" [(ngModel)]="fields.MMERGE7">
                </div>
                <div class="form-group">
                    <label for="current">How do you manage your inventory currently?</label>
                    <input type="text" id="current" class="form-control" placeholder="Manually adjust inventory on all sales channels" [(ngModel)]="fields.MMERGE8">
                </div>
                <div class="form-group">
                    <label for="features">What features are you looking for most?</label>
                    <input type="text" id="features" class="form-control" placeholder="Prevent over selling" [(ngModel)]="fields.MMERGE9">
                </div>
                <re-captcha (resolved)="resolved($event)" siteKey="6LeYhHUUAAAAALZyIIBrtBmx5z9ep6JHAsW_boAF"></re-captcha>
            </fieldset>
        </div>
        <div *ngIf="message != ''" class="mt-2">
            <alert type="danger"><span [innerHtml]="message"></span></alert>
        </div>
        <button *ngIf="showSubmitButton" type="button" class="btn btn-success mt-2 float-right" (click)="submit()" [disabled]="validation.human == false">Sign Up</button>
    `
})
export class EmailSignUpComponent {
    @Input() showSubmitButton = true;
    @Input() showDescription = true;
    @Output() complete = new EventEmitter<boolean>();

    fields = {
        EMAIL: '',
        FNAME: '',
        LNAME: '',
        MMERGE6: '', // marketplaces
        MMERGE7: '', // storefront
        MMERGE8: '', // current application
        MMERGE9: ''  // must have features
    };
    validation = {
        email: false,
        human: false
    };
    message = '';

    endpoint = AppService.config.mailChimpEndpoint;
    emailRegEx = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    constructor(
        private http: HttpClient
    ) {}

    checkEmail() {
        this.validation.email = this.emailRegEx.test(this.fields.EMAIL);
    }

    resolved(captchaResponse: string) {
        console.log('Resolved captcha with response:', captchaResponse);
        this.validation.human = (captchaResponse !== null);
    }

    submit() {
        this.message = '';
        this.checkEmail();

        if (this.validation.email != true) {
            this.message = 'Invalid email address';
            return;
        }

        // https://medium.com/briebug-blog/mailchimp-subscribe-form-with-angular-5-using-jsonp-f5f54193f8d1
        let params = new HttpParams();
        for (let key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
                params = params.set(key, this.fields[key]);
            }
        }

        this.http.jsonp<MailChimpResponse>(`${this.endpoint}&${params.toString()}`, 'c')
            .subscribe((response) => {
                console.log('=-->', response);
                if (response.result && response.result !== 'error') {
                    // signal done
                    this.complete.emit(true);
                } else {
                    this.message = (response.msg) ? response.msg : 'Unknown error response';
                }
            }, (error) => {
                console.log('Error signing up', error);
                this.message = 'Error submitting request';
            })
    }
}
