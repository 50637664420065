import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-vendors',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_vendors.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Vendor Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Keep Re-Order Information with the Product</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-vendor.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/vendor.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Add vendors to products with related reordering product information</li>
                            <li>Mark a vendor as preferred for each product</li>
                            <!--<li>Re-order reports and Purchase Order generation for vendors</li>-->
                        </ul>
                        <p>
                            Organize your vendors and assign vendors to each product. Multiple vendors can be assigned
                            to a single product allowing you to set a preferred vendor.
                        </p>
                        <p>
                            You set individual notes, order thresholds, lead time and vendor SKUs to the product details
                            for each specific vendor to make re-ordering easy.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class VendorsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
