import { Component, } from '@angular/core';

@Component({
    selector: 'app-about-us',
    template: `
        <div class="container">
            <div class="row">
                <div class="col col-12">
                    <h1>About Us</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Faucibus a pellentesque sit amet porttitor eget dolor. Mauris nunc congue nisi vitae
                        suscipit tellus. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Gravida neque
                        convallis a cras semper. Quis hendrerit dolor magna eget est lorem ipsum. Aliquet sagittis id consectetur
                        purus. Praesent semper feugiat nibh sed pulvinar. Felis eget velit aliquet sagittis id consectetur purus.
                        Sed turpis tincidunt id aliquet risus feugiat in. Faucibus in ornare quam viverra orci sagittis eu volutpat.
                        Nunc lobortis mattis aliquam faucibus purus. Nibh venenatis cras sed felis eget velit aliquet sagittis id.
                        Phasellus vestibulum lorem sed risus ultricies. Amet luctus venenatis lectus magna fringilla urna. Libero
                        justo laoreet sit amet cursus sit amet. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien
                        et. Dignissim enim sit amet venenatis urna. Facilisis gravida neque convallis a. Odio facilisis mauris sit
                        amet massa vitae tortor condimentum lacinia.
                    </p>
                    <p>
                        Nulla facilisi etiam dignissim diam quis enim lobortis. Sit amet dictum sit amet justo. Nunc mattis enim ut
                        tellus elementum. In nulla posuere sollicitudin aliquam ultrices. Accumsan tortor posuere ac ut consequat.
                        Vulputate mi sit amet mauris commodo quis imperdiet massa tincidunt. Non curabitur gravida arcu ac tortor
                        dignissim convallis. Odio tempor orci dapibus ultrices in iaculis. Mauris augue neque gravida in. Viverra
                        maecenas accumsan lacus vel facilisis volutpat est velit egestas.
                    </p>
                    <p>
                        Maecenas volutpat blandit aliquam etiam erat velit. Varius quam quisque id diam vel. Ac ut consequat semper
                        viverra. Morbi tristique senectus et netus et malesuada fames ac turpis. Neque gravida in fermentum et.
                        Consectetur adipiscing elit ut aliquam purus sit amet. Venenatis urna cursus eget nunc scelerisque viverra
                        mauris in aliquam. Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a. Consequat nisl vel pretium
                        lectus quam id. Dignissim cras tincidunt lobortis feugiat. Sit amet nulla facilisi morbi tempus iaculis.
                        Amet mauris commodo quis imperdiet massa tincidunt. Urna et pharetra pharetra massa massa ultricies. In
                        massa tempor nec feugiat nisl pretium fusce id velit. Dictumst vestibulum rhoncus est pellentesque elit.
                        Parturient montes nascetur ridiculus mus mauris. Vel fringilla est ullamcorper eget nulla facilisi etiam
                        dignissim. Magna fringilla urna porttitor rhoncus dolor. Nisl nunc mi ipsum faucibus vitae aliquet nec
                        ullamcorper sit.
                    </p>
                    <p>
                        Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Quisque egestas diam in arcu
                        cursus euismod quis viverra nibh. Fusce ut placerat orci nulla pellentesque dignissim enim sit amet. A erat
                        nam at lectus urna duis. Rhoncus urna neque viverra justo nec. Consectetur a erat nam at lectus urna duis
                        convallis convallis. Est placerat in egestas erat imperdiet sed euismod nisi porta. Odio euismod lacinia at
                        quis. In massa tempor nec feugiat nisl pretium fusce id velit. Posuere morbi leo urna molestie at elementum.
                        Pellentesque sit amet porttitor eget dolor morbi non arcu risus. Tincidunt dui ut ornare lectus sit. Ac
                        auctor augue mauris augue neque gravida in fermentum et.
                    </p>
                    <p>
                        Blandit aliquam etiam erat velit scelerisque in. Feugiat nisl pretium fusce id velit ut tortor pretium
                        viverra. Enim neque volutpat ac tincidunt vitae. Eget mauris pharetra et ultrices neque ornare. Amet
                        volutpat consequat mauris nunc. Vestibulum lorem sed risus ultricies tristique nulla aliquet enim tortor.
                        Enim ut tellus elementum sagittis. Tempor id eu nisl nunc mi ipsum faucibus vitae aliquet. Scelerisque purus
                        semper eget duis at tellus at. Ipsum a arcu cursus vitae congue mauris rhoncus aenean vel. Ut morbi
                        tincidunt augue interdum. Commodo elit at imperdiet dui accumsan. Et malesuada fames ac turpis egestas
                        maecenas pharetra convallis. Cursus euismod quis viverra nibh cras pulvinar mattis nunc. At lectus urna duis
                        convallis convallis tellus id. Mattis ullamcorper velit sed ullamcorper morbi. In fermentum posuere urna nec
                        tincidunt. Condimentum vitae sapien pellentesque habitant.
                    </p>
                    <p>
                        Tortor aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Non curabitur gravida arcu ac tortor.
                        Cursus vitae congue mauris rhoncus. Feugiat nisl pretium fusce id velit ut. Gravida quis blandit turpis
                        cursus in hac habitasse platea. Penatibus et magnis dis parturient. Sit amet tellus cras adipiscing enim eu
                        turpis egestas. Fusce id velit ut tortor pretium viverra. Vestibulum sed arcu non odio euismod lacinia at
                        quis. Id nibh tortor id aliquet. Eu consequat ac felis donec et odio pellentesque diam volutpat.
                    </p>
                </div>
            </div>
        </div>
    `
})
export class AboutUsComponent {}
