import { Component, OnInit } from '@angular/core';
import { AppService } from "@shared/app.service";

@Component({
    selector: 'app-features-bundles',
    template: `        
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_bundles.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Bundle/Package Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Track Cost and Inventory of Components</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-4">
                            Product Bundle/Packages
                            <span class="badge badge-brand-b float-right">{{appConfig.featureCosts.components | currency:'USD':'symbol':'1.0'}} Add-On</span>
                        </h2>
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-pack.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/pack.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Create packages from master items</li>
                            <li>Reduce inventories of pack component</li>
                            <li>Use for hand made goods to calculate costs and labor</li>
                            <li>Use for repackaging bulk wholesale items</li>
                            <!--<li>Create <i>live</i> bundles of products to reduce quantities of components when sold</li>-->
                        </ul>
                        <p>
                            The Package Management Feature is an add-on module that can be used to make kits or a
                            grouping of products under a single item sku. This tool will automatically cost out your new
                            packaged sku and reduce quantities from the components dynamically when the quantity of the
                            packaged sku is increased.
                        </p>
                        <p>
                            This tool can be used to cost products that may be hand made or require labor to be factored
                            in by creating an inactive product for a labor cost.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class BundlesComponent implements OnInit {

    appConfig = AppService.config;

    constructor() { }

    ngOnInit() {
    }

}
