import { Component, OnInit } from '@angular/core';
import { AppService } from "@shared/app.service";

@Component({
    selector: 'app-features-integrations',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_integration.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Integration Management</h1>
                    </div>
                </div>
                <!--<div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Quickly See Your Order Summary</h2>
                    </div>
                </div>-->
                <div class="row">
                    <div class="col-12">
                        <!--<div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-dashboard.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/dashboard.mp4" type="video/mp4">
                            </video>
                        </div>-->
                        <div class="border v-wrap ml-md-4 mb-2">
                            <img src="/assets/images/ss-connection-etsy.jpg" style="width:100%;" />
                        </div>
                        <ul class="branded mb-4">
                            <li>
                                Marketplaces: Amazon (US, Canada, Mexico), eBay, Etsy, Faire, Sears and Walmart
                            </li>
                            <li>
                                Shopping Carts: WooCommerce, Zencart, BigCommerce and Shopify
                            </li>
                            <li>Free Point of Sale integration</li>
                            <li>Other integrations are available on request</li>
                            <li>
                                Three sales channel integrations included
                                <span class="badge badge-brand-b">{{appConfig.featureCosts.additionalChannels | currency:'USD':'symbol':'1.0'}} Add-On Per Additional</span>
                            </li>
                        </ul>
                        <p>
                            Our sales channel integration works so you can have multiple connections to a single
                            marketplace. This is especially useful for Amazon that requires separate credentials to
                            have a store in each region.
                        </p>
                        <p>
                            Free Point of Sale (POS) integration uses PayPal Here or Square to process payments. This
                            integration is a separate application that runs on your phone or tablet and has direct
                            access to your products and inventory. We designed the POS to work for our customers who
                            sell at events.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class IntegrationsComponent implements OnInit {

    appConfig = AppService.config;

    constructor() { }

    ngOnInit() {
    }

}
