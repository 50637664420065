import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-orders',
    template: `        
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_orders.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Order Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Order Details All In One Place</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-orders.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/orders.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Imports orders from all sales channels</li>
                            <li>Quick view of order date, sales channel, order id, subtotal and SKU counts</li>
                            <li>Cancel orders before they ship</li>
                            <li>One click packing slip or invoice creation</li>
                            <li>Pending status for products sold with check or money order option</li>
                            <li>Historical order search by date, order number, status, customer name, product SKU or product title</li>
                            <!--<li>FBA sales tracking for sales reporting**</li>-->
                        </ul>
                        <p>
                            The order detail page automatically imports your orders from all connected sales channels.
                            Easy one click packing slip creation for pull and pack with the ability to provide custom
                            packing slip messaging.
                        </p>
                        <p>
                            Selecting an order provides details for all order specifics including shipping method
                            requested and the list of products purchased. This also allows you to change the shipping 
                            date and send it to the Pending Orders page.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class OrdersComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
