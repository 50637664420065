import { Component, } from '@angular/core';
import { AppService } from '@shared/app.service';

@Component({
    selector: 'app-about-us',
    template: `
        <section id="faq">
            <div class="container">
                <div class="row">
                    <div class="col col-12">
                        <h1>Frequently Asked Questions</h1>
                        
                        <div class="question-answer">
                            <h5>How does the free trial work?</h5>
                            <p>
                                For 30 days, you receive access to all of {{appConfig.shortName}}'s features, and no
                                credit card is required for the 30 days. On the 30th day, you will receive an email 
                                indicating what your bill would be for the following month with all the features 
                                available. There are an additional five days in which you can provide your billing 
                                information and make changes to the features you need. On the 35th day, your account 
                                will be billed, providing you choose to continue your service. If, on the 35th day, you 
                                choose not to continue your service, you do not need to do anything further.
                            </p>
                        </div>
                        <div class="question-answer">
                            <h5>Are there any setup fees?</h5>
                            <p>
                                There are no set up fees, we will even provide an hour of our time to help you get set
                                up. If you need additional assistance over and above a few questions here or there, our
                                team can be hired hourly for the setup.
                            </p>
                        </div>
                        <div class="question-answer">
                            <h5>Can you provide assistance with setup?</h5>
                            <p>
                                Yes. We will provide you with 1 hour of assistance to make sure you get setup with your
                                sales channels. We are also always available through our support channel in the application.
                            </p>
                        </div>
                        <div class="question-answer">
                            <h5>Am I locked into a contract?</h5>
                            <p>
                                No. Your subscription is renewed month to month. If you do not wish to continue, please
                                contact us through your dashboard to notify us of a change. No refunds will be provided,
                                as your month is prepaid. If you choose to end your subscription, it will be effective
                                at the end of the month paid.
                            </p>
                        </div>
                        <div class="question-answer">
                            <h5>How secure is my data?</h5>
                            <p>
                                We strive to stay up to date with industry best practices and keep all systems up to
                                date. All payment systems are fully PCI compliant and handled by Braintree Payments. All
                                sales channel API credentials and customer shipping information is stored in an
                                encrypted form. For full details, look at our
                                <a routerLink="/security">Security Overview</a>.
                            </p>
                        </div>
                        <div class="question-answer">
                            <h5>What if I don't want my sales channels updated?</h5>
                            <p>
                                {{appConfig.shortName}} allows you to disable the price and quantity updates. This will 
                                mean that the quantities on your sales channels will not match what is shown in
                                {{appConfig.shortName}}.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class FaqComponent {
    appConfig = AppService.config;
}
