import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    template: `
        <router-outlet></router-outlet>
        <!--<div class="container-fluid">
            <div class="row mt-4">
                <div class="col offset-2 col-8">
                    <p class="text-muted small text-center">
                        The demo version will be updated with new features as they are implemented. We recommend signing
                        up for our email announcements to find out when the demo version has these features available.
                    </p>
                </div>
            </div>
        </div>-->
    `
})
export class MainComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
