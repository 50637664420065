import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PricingComponent } from '@app/pricing/pricing.component';
import { MainComponent as FeaturesComponent } from '@app/features/main.component';
import { DashboardComponent as DashboardFeatureComponent } from '@app/features/dashboard.component';
import { OrdersComponent as OrdersFeatureComponent } from '@app/features/orders.component';
import { ReturnsComponent as ReturnsFeatureComponent } from '@app/features/returns.component';
import { ProductsComponent as ProductsFeatureComponent } from '@app/features/products.component';
import { BundlesComponent as BundlesFeatureComponent } from '@app/features/bundles.component';
import { PricingComponent as PricingFeatureComponent } from '@app/features/pricing.component';
import { VendorsComponent as VendorsFeatureComponent } from '@app/features/vendors.component';
import { ImportExportComponent as ImportExportFeatureComponent } from '@app/features/importExport.component';
import { TasksComponent as TasksFeatureComponent } from '@app/features/tasks.component';
import { ReportsComponent as ReportsFeatureComponent } from '@app/features/reports.component';
import { IntegrationsComponent as IntegrationsFeatureComponent } from '@app/features/integrations.component';
import { PackingSlipsComponent as PackingSlipsFeatureComponent } from '@app/features/packingSlips.component';
import { SubscriptionComponent as SubscriptionFeatureComponent } from '@app/features/subscription.component';
import { AboutUsComponent } from '@app/additional/aboutUs.component';
import { DemoComponent } from '@app/additional/demo.component';
import { FaqComponent } from '@app/additional/faq.component';
import { SecurityComponent } from '@app/additional/security.component';

const appRoutes: Routes = [
    { path: 'pricing', component: PricingComponent },
    {
        path: 'features',
        component: FeaturesComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardFeatureComponent },
            { path: 'orders', component: OrdersFeatureComponent },
            { path: 'returns', component: ReturnsFeatureComponent },
            { path: 'products', component: ProductsFeatureComponent },
            { path: 'bundles', component: BundlesFeatureComponent },
            { path: 'pricing', component: PricingFeatureComponent },
            { path: 'vendors', component: VendorsFeatureComponent },
            { path: 'import-export', component: ImportExportFeatureComponent },
            { path: 'tasks', component: TasksFeatureComponent },
            { path: 'reports', component: ReportsFeatureComponent },
            { path: 'integrations', component: IntegrationsFeatureComponent },
            { path: 'packing-slips', component: PackingSlipsFeatureComponent }, // not linked
            { path: 'subscription', component: SubscriptionFeatureComponent } // not linked
        ]
    },
    { path: 'about-us', component: AboutUsComponent }, // not linked
    { path: 'demo', component: DemoComponent},
    { path: 'faq', component: FaqComponent },
    { path: 'security', component: SecurityComponent },
    { path: 'legal', loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule) },
    { path: '', component: HomeComponent },
    { path: '**', component: HomeComponent }
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollOffset: [0, 110] // AOT fails when getting 110 from AppService
};

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, routerOptions)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class AppRoutingModule { }
