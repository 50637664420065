import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AppService } from '@shared/app.service';

@Injectable()
export class SeoService {
    // descriptions should describe each page and be different, 55-160 characters long
    descriptions = {
        'default': `${AppService.config.name} is an online selling tool that syncs and updates inventory across multiple sale channels and shopping carts. Free 30 day trial and live demo available.`,
        'pricing': `${AppService.config.shortName} offers volume pricing, meaning your bill goes down when your sales are slow and increases as your business does. Never pay more than the lowest available price for your subscription.`,
        'features.dashboard': `${AppService.config.shortName}'s easy to use interface features a dashboard summarizes the past 30 days of sales by order count, number of items, sales channel and more. Provides a quick view of Orders ready to ship and active inventory.`,
        'features.orders': `${AppService.config.shortName} automatically imports sales from each marketplace and shopping cart, updating inventory levels by syncing to each sales channel. One click customized packing slip creation for easy order management.`,
        'features.returns': `${AppService.config.shortName} helps track and restock inventory from returned items, updating sales data accordingly for an accurate revenue reporting. Create custom return reasons for internal tracking.`,
        'features.products': `${AppService.config.shortName} is inventory-centric, not listing centric. Manage the details of your inventory and set separate calculated prices for each sales channel or marketplace. Include shipping in your prices on Amazon or Walmart.`,
        'features.bundles': `${AppService.config.shortName} makes it easy to cost and price a bundled package. Link to active or inactive products to create packaged products such as kits or hand made goods.`,
        'features.pricing': `${AppService.config.shortName} comes equipped with calculators to help you adjust your prices based on cost, shipping and packaging as well as markups. Create custom prices to create a base price and sale channel calculations for the individual marketplaces.`,
        'features.vendors': `${AppService.config.shortName} organizes vendors and allows products to be tagged with multiple vendors for easier inventory management when restocking. Keep your vendor's SKUs linked to your inventory.`,
        'features.import-export': `${AppService.config.shortName} offers a one click import for your first sale channel import and automatic linking when connecting additional marketplaces and shopping carts.`,
        'features.tasks': `${AppService.config.shortName} offers both phone support and instant messaging support as well as self guided software tutorials to get up and running. Set up tasks to help you manage your day to day operations.`,
        'features.reports': `${AppService.config.shortName} makes reports easy to control. Create custom tags for products to compare whether certain colors sell better, or create and/or download a full sales report including profit, costs and revenue.`,
        'features.integrations': `${AppService.config.shortName} integrates with many marketplaces and shopping carts: Amazon, Walmart, Etsy, Ebay, Sears, ZenCart, BigCommerce, Shopify and Faire. Requesting additional marketplaces is encouraged. We will build it for you.`,
        'faq': `${AppService.config.shortName} provides an extensive knowledge base. If you cannot find the answer, feel free to call us or message us to get your answers.`,
        'demo': `${AppService.config.shortName} offers a fully accessible demo account that you can edit to your hearts content. Each demo account is created individually and you can log in and alter products or play with tools for up to 24 hours.`
    };

    constructor(
        private title: Title,
        private meta: Meta
    ) {}

    update(navSplit) {
        this.setPageTitle(navSplit);
        this.setPageDesc(navSplit);
    }

    setPageTitle(navSplit) {
        let words;
        let wording = [];
        switch (navSplit[0]) {
            case 'features' :
            case 'legal' :
                words = navSplit[1].replace(/(-|_)/, ' ').split(' ');
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                }
                wording.push(words.join(' '));
                break;
            case 'demo' :
                wording.push('Demo Account Information');
                break;
            case 'faq' :
                wording.push('FAQ');
                break;
            default :
                if (typeof navSplit[0] == 'string' && navSplit[0] != '') {
                    words = navSplit[0].replace(/(-|_)/, ' ').split(' ');
                    for (let i = 0; i < words.length; i++) {
                        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                    }
                    wording.push(words.join(' '));
                }
        }

        wording = wording.filter((w) => {
            return w != '';
        });

        if (wording.length == 0) {
            wording = [AppService.config.longName];
        } else {
            wording.push(AppService.config.shortName);
        }

        this.title.setTitle(wording.join(' - '));
    }

    setPageDesc(navSplit) {
        const key = navSplit.join('.');
        let desc = this.descriptions.default;
        if (this.descriptions.hasOwnProperty(key)) {
            desc = this.descriptions[key];
        }
        this.meta.updateTag({name: 'description', content: desc});
    }
}
