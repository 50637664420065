import { Component, ViewChild } from '@angular/core';
import { BSModalContext } from 'ngx-modialog/plugins/bootstrap';
import { DialogRef, ModalComponent } from 'ngx-modialog';

@Component({
    selector: 'modal-content',
    template: `
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
            <h3 class="modal-title">Get Announcements and Updates</h3>
        </div>
        <div class="modal-body">
            <email-list-signup #emailListForm [showSubmitButton]="false" (complete)="complete()"></email-list-signup>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success" (click)="save()" [disabled]="emailListForm.validation.human == false">Sign Up</button>
            <button class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
    `
})
export class EmailSignUpDialog implements ModalComponent<BSModalContext> {
    @ViewChild('emailListForm', { static: false }) emailListForm;

    private context: BSModalContext;

    constructor(
        public dialog: DialogRef<BSModalContext>
    ) {
        this.context = dialog.context;
    }

    save() {
        this.emailListForm.submit();
    }

    complete() {
        this.dialog.close(true);
    }

    cancel() {
        this.dialog.dismiss();
    }
}
