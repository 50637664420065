import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-tasks',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_inventory.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Task Management and Support</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Personal Support from the Founders</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-tasks.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/tasks.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Self-guided tasks and emails for implementation</li>
                            <!--<li>Custom tasks per user*</li>-->
                            <li>Instant chat online during business hours</li>
                            <li>Rapid email support during business hours</li>
                            <li>Call back phone support during business hours</li>
                        </ul>
                        <p>
                            No need for hands on implementation, although it is available. Follow our predefined tasks
                            for self guided implementation. Send us messages through the chat feature or search through
                            our knowledge base for answers to your frequently asked questions.
                        </p>
                        <p>
                            <strong>For a limited time</strong>, we are offering one hour of on-boarding at no charge
                            for those who need a little more hand holding through the implementation process.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class TasksComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
