import { Component, OnInit, Inject } from '@angular/core';
import { EmailSignUpDialog } from "@shared/emailSignUp.dialog";
import { Modal, BSModalContext } from 'ngx-modialog/plugins/bootstrap';
import { Overlay, overlayConfigFactory } from 'ngx-modialog';
import { AppService } from '@shared/app.service';

declare let jQuery: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
    appConfig = AppService.config;
    faderIndex = 0;

    constructor(
        private modal: Modal,
    ) {
        jQuery((<any>window)).resize(() => {
            this.setSalesBannerHeight();
        });
    }

    setSalesBannerHeight() {
        const windowHeight = (<any>window).innerHeight;
        const actionHeight = jQuery('#sales-action').outerHeight();
        const channelHeight = jQuery('#sales-channels').outerHeight();
        let bannerHeight = windowHeight - (actionHeight + channelHeight + AppService.config.settings.navBarHeight + 100);
        if (bannerHeight < 300) {
            bannerHeight = 300;
        }
        jQuery('#sales-banner').css({height: bannerHeight});
        jQuery('.sayings').css({top: (bannerHeight / 2.5)})
    }

    ngOnInit() {
        setInterval(() => {
            const el = jQuery('.sayings div');

            jQuery(el[this.faderIndex]).css({opacity: 0});

            this.faderIndex++;
            if (this.faderIndex > el.length - 1)
                this.faderIndex = 0;

            jQuery(el[this.faderIndex]).css({opacity: 1});
        }, 5000);

        setTimeout(() => {
            this.setSalesBannerHeight();
        }, 500);
    }

    appEndpoint = AppService.config.appEndpoint;

    mailingListSignup() {
        this.modal.open(
            EmailSignUpDialog, overlayConfigFactory({}, BSModalContext)
        ).result.then(() => {
            // console.log('complete');
        }, () => {
            // have to have a cancel method
        });
    }

    contactForm() {

    }
}
