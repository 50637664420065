import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-import',
    template: `
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_export.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Product Import/Export Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Easily Import Sales Channel Listings</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-import.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/import.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>One-click import from sales channels</li>
                            <li>Auto linking of sales channels SKUs with master SKUs</li>
                            <li>Simple manual linking for products with different SKUs</li>
                            <li>Export inventory by CSV for bulk editing</li>
                            <li>Import updated inventory information</li>
                            <!--<li>Import new items</li>-->
                        </ul>
                        <p>
                            Easily import all product listings into your product catalog. All product link automatically
                            to your product catalog if you use matching SKUs. For mis-matched SKUs, you can manually
                            link your products or create new SKUs from your listings.
                        </p>
                        <p>
                            Export your product list for bulk updating or for your own custom reasons.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class ImportExportComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
