import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features-products',
    template: `        
        <section class="feature-item">
            <div class="container">
                <div class="row feature-title">
                    <div class="col-4 col-md-2">
                        <img src="/assets/images/features_icon_products.png" class="icon" />
                    </div>
                    <div class="col-8 col-md-10">
                        <h1>Product Management</h1>
                    </div>
                </div>
                <div class="row feature-headline">
                    <div class="col-12">
                        <h2 class="hand-font">Manage Your Products, Not Listings</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-products-dashboard.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/products-dashboard.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Sync sales channel inventories with one master inventory</li>
                            <li>Active products for selling and inactive products for components of products</li>
                            <li>Price control for individual sales channels</li>
                            <li>Low stock management – create reorder thresholds and desired quantities</li>
                        </ul>
                        <p>
                            Manage all the products in your catalog, including active, inactive and archived items.
                            Easily search through your products for keywords, item skus or names.
                        </p>
                        <p>
                            Utilize the inactive product assignment for internal tracking of supplies, components or any
                            items you wish to track but not include in your product catalog.
                        </p>
                        <p>
                            Quickly view the items sitting at or below your re-order threshold. Set this threshold 
                            individually for each product to notify you of low stock.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <h2 class="mb-4 hr">Products Details</h2>
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-product-details.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/product-details.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Quick view of product SKU, title, product group, base/master price, quantity and product tags</li>
                            <li>View sales channel summary information with sales channel SKU and custom prices</li>
                            <li>Complex product costing and pricing available via Enhanced Pricing Feature</li>
                            <li>Vendor assignment with custom vendor coding and notes, assign multiple vendors and preferred vendor status</li>
                            <li>Product details for descriptions, UPC, weight, unlimited images per SKU</li>
                            <li>Assign product cost for profit reporting</li>
                            <li>Virtual product assignment</li>
                            <li>Additional custom fields</li>
                        </ul>
                        <p>
                            All your product details are in one place. Tag your products that are digital. Individually
                            set and manage your sales channel's pricing either manually or through our Enhanced Pricing 
                            Module with a custom calculation.
                        </p>
                        <p>
                            Keep custom reordering information for the assigned vendor as well as set a preferred vendor
                            for each product. Add custom fields for additional product details.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <h2 class="mb-4 hr">Groups</h2>
                        <div class="embed-responsive embed-responsive-16by9 border v-wrap ml-md-4 mb-2">
                            <video height="720" width="1280" controls="controls" poster="/assets/images/ss-groups.jpg">
                                <source src="https://d1g67sbwkyt2tm.cloudfront.net/groups.mp4" type="video/mp4">
                            </video>
                        </div>
                        <ul class="branded mb-4">
                            <li>Group products with a parent SKU</li>
                            <li>Automate product naming within groupings</li>
                            <li>Assign variation attributes</li>
                            <li>Assign vendor and notes</li>
                        </ul>
                        <p>
                            Group products with a parent sku. Auto-create names and descriptions based on personalized
                            variation parameters. Bulk assign product tags for reporting and vendor details.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class ProductsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
