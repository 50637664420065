import { Component } from '@angular/core';

@Component({
    selector: 'app-home-features',
    template: `
        <div class="row justify-content-center">
            <div class="col col-6 col-md-3">
                <a routerLink="/features/products" class="widget">
                    <img src="/assets/images/features_icon_products.png" />
                    <h5>Inventory Sync</h5>
                    <p>
                        Synchronize inventory levels on all your sales channel from one master inventory.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/integrations" class="widget">
                    <img src="/assets/images/features_icon_integration.png" />
                    <h5>FREE Point of Sale</h5>
                    <p>
                        Use our POS interface at events or in your store. Payments are processed through PayPal Here or Square.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/pricing" class="widget">
                    <img src="/assets/images/features_icon_pricing.png" />
                    <h5>Built-In Pricing Calculations</h5>
                    <p>
                        The power of spreadsheet calculations for each product on each sales channel. When shipping prices change,
                        just update one value to change all your free shipping products.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/bundles" class="widget">
                    <img src="/assets/images/features_icon_bundles.png" />
                    <h5>Packages</h5>
                    <p>
                        Create packages of items to track cost and inventory, even for items you don't sell individually.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/orders" class="widget">
                    <img src="/assets/images/features_icon_orders.png" />
                    <h5>All Orders In One Interface</h5>
                    <p>
                        Manage all your orders from one interface. One click packing slip creation.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/returns" class="widget">
                    <img src="/assets/images/features_icon_returns.png" />
                    <h5>Restock Returned Items</h5>
                    <p>
                        When items are returned, you can have the system automatically add the inventory back. Include
                        custom return reasons and additional notes.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/groups" class="widget">
                    <img src="/assets/images/features_icon_groups.png" />
                    <h5>Group Related Products</h5>
                    <p>
                        Simplify product management with product groups. 
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/vendors" class="widget">
                    <img src="/assets/images/features_icon_vendors.png" />
                    <h5>Manage Your Vendor List</h5>
                    <p>
                        Keep product reorder information with the products, not separate records.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/import-export" class="widget">
                    <img src="/assets/images/features_icon_export.png" />
                    <h5>Import &amp; Export Product Information</h5>
                    <p>
                        Export product attributes via CSV for easy modification by spreadsheet re-import the changes.
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/tasks" class="widget">
                    <img src="/assets/images/features_icon_inventory.png" />
                    <h5>Create In-App Task Reminders</h5>
                    <p>
                        Create reminders and set deadlines for you and your employees about tasks. 
                    </p>
                </a>
            </div>
            <div class="col col-6 col-md-3">
                <a routerLink="/features/reports" class="widget">
                    <img src="/assets/images/features_icon_reports.png" />
                    <h5>Customized Drill-Down Reporting</h5>
                    <p>
                        Product tagging allows an in-depth drill-down report so you know exactly what category
                        or product type is selling the most.
                    </p>
                </a>
            </div>
            <!--<div class="col col-6 col-md-3">
                <a routerLink="/features/packing-slips" class="widget">
                    <img src="/assets/images/features_icon_reports.png" />
                    <h5>Customized Packing Slips</h5>
                    <p>
                        Each sales channel can have it's own customized packing slip. Everything from logo to the
                        thank you message.
                    </p>
                </a>
            </div>-->
            <div class="col col-6 col-md-3">
                <a routerLink="/pricing" class="widget">
                    <img src="/assets/images/features_icon_billing.png" />
                    <h5>Volume Pricing</h5>
                    <p>
                        Monthly cost that automatically adjust with the number of sales.
                    </p>
                </a>
            </div>
        </div>
    `
})
export class HomeFeaturesComponent {

}
